(function ($) {
  window.site = site || {};
  site.offers = site.offers || {};
  site.offers.welcome15 = site.offers.welcome15 || {};

  // --------
  // Primary behavior responsible for initializing the welcome15 offer logic

  Drupal.behaviors.ELB_welcome15 = {
    // debug config
    debug: false, // when debugging, the overlay opens on all page loads
    showThanks: true, // even if there's an error, show the "thank you" message
    showSignedUp: false, // even if there's an error, show the "already signed up" message

    // storage cookie name
    offerCookie: 'welcome15',

    // # of seconds before the overlay appears
    defaultTimeout: 3000,

    // template path config
    templates: {
      form: 'welcome_15',
      // already_signed_up: 'welcome15_offer_signed_up', Not used, always show the success regardless of if signed up before or not
      thanks: 'welcome_15_success',
      error: 'welcome15_offer_error',
      disclaimer: 'welcome15_offer_disclaimer'
    },

    // overlay config
    overlay: {
      pc: {
        className: 'welcome15-overlay',
        transition: 'none',
        height: '280px'
      },
      mobile: {
        className: 'welcome15-overlay',
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        opacity: '0',
        transition: 'none'
      }
    },

    isStr: function (str) {
      return typeof str === 'string';
    },

    isFunc: function (func) {
      return typeof func === 'function';
    },

    isObj: function (mixed_var) {
      if (Object.prototype.toString.call(mixed_var) === '[object Array]') {
        return false;
      }

      return mixed_var !== null && typeof mixed_var === 'object';
    },

    /**
     * Helper function to get the raw contents of an JS inline template, and optionally interpolate
     * the html using Mustache
     */
    getTemplateContent: function (key, data) {
      var undef;
      // Sanitize the incoming data
      var path = key !== undef ? key : 'foobar_template';

      data = data || false;

      // Get the html content of the template
      var html = site.template.get({
        name: key,
        data: data
      });

      if (html.length == 0) {
        return $();
      }

      // If there's no data object passed in, then just return a basic jquery element
      if (data == false || !$.isFunction(Mustache.render)) {
        return $(html);
      }

      // We have html and data, which means we should use Mustache to render the output
      return $(Mustache.render(html, data));
    },

    // Open a colorbox window
    openWindow: function (content, callBacks) {
      var self = this;

      content = self.isObj(content) ? content : $();

      // Dumb trick to get the entire contents of the html out of the jquery object
      content = content.wrap('<div />').parent().html();

      // Add welcome15 body CSS class
      $('body').addClass('welcome15');

      if (!$.isFunction($.colorbox)) {
        return false;
      }

      var args = {
        html: content,
        height: '352px',
        maxWidth: '100%',
        maxHeight: '100%',
        width: '461px'
      };
      var darg = $('body').hasClass('device-mobile') ? self.overlay.mobile : self.overlay.pc;

      $.extend(args, darg);

      if (self.isObj(callBacks)) {
        for (var k in callBacks) {
          var func = callBacks[k];

          if (self.isFunc(func)) {
            args[k] = func;
          }
        }
      }

      $.colorbox(args);
    },

    // Show the signup form
    showSignupForm: function (popup_timeout) {
      var self = this;

      setTimeout(function () {
        var content = self.getTemplateContent(self.templates.form);
        // There is an input that creates a div that we can grab and specify an alternate layout class for i224733
        var useAltMarkUp = $(content).find('#USE_ALT_LAYOUT').length;

        if (useAltMarkUp) {
          cboxArgs = $('body').hasClass('device-mobile') ? self.overlay.mobile : self.overlay.pc;
          cboxArgs.className = 'welcome15-overlay alt_layout1';
          cboxArgs.fixed = true;
        }

        self.openWindow(content, {
          // Hide the content and overlay in onOpen, so we can fade it in initSignUpForm
          // This prevents "sliding" while the overridden css is loading, and also makes the overlay and content fade in simultaneously
          onOpen: function () {
            $('.welcome15-overlay #cboxContent,#cboxOverlay').css('visibility', 'hidden');
          },
          onComplete: function () {
            site.offers.welcome15.initSignUpForm();
            $('.terms-conditions-link').on('click', function (e) {
              e.preventDefault();
              $('.site-email-signup__terms-details').show();
              $('.site-email-signup__terms-details').animate({
                height: '100%',
                width: '100%',
                overflow: 'auto'
              });
            });

            $('.site-email-signup__close-terms').on('click', function (e) {
              e.preventDefault();
              $('.site-email-signup__terms-details').animate(
                {
                  height: 0,
                  width: 0,
                  overflow: 'hidden'
                },
                function () {
                  $('.site-email-signup__terms-details').hide();
                }
              );
            });
            // This fixes a bug on the m. domain where the colorbox
            // would take up the full height on mobile devices.
            if ($('body').hasClass('device-mobile')) {
              $.colorbox.resize();
            }
          }
        });
      }, popup_timeout);
    },

    /**
     * Helper function to determine if the welcome15 offer should appear on this page
     * @TODO: this will likely be expanded to be disabled on checkout and whatnot
     */
    showOfferOnPage: function () {
      var self = this,
        cookieName = self.offerCookie,
        hasCookie = $.cookie(cookieName),
        showOffer = false,
        undef;
      // adding to allow manual disabling of the popover
      var disabled = 0;

      if (hasCookie == undef) {
        // there is a div in the mustache that gets printed based on the disabled checbox input welcome15_offer_signup_v1
        var markUp = self.getTemplateContent(self.templates.form);
        var markUpDisabled = $(markUp).find('#DISABLE_POPOVER');

        // set the session cookie if the page is disabled, rather than checking every time
        if (markUpDisabled.length > 0) {
          $.cookie(cookieName, '1', {
            expires: 1,
            path: '/'
          });

          return false;
        }
      }

      if (self.debug || hasCookie == undef) {
        showOffer = true;
      }

      return showOffer;
    },

    // Main function to get things kicked off
    attach: function (context, settings) {
      var self = this,
        cookieName = self.offerCookie;
      // TO disable offer popup
      var siteSearch = window.location.search || '';
      var extoleString = /[?&]utm_source=\bextole\b/;

      // Trigger link to open welcome15
      $('.js-welcome-15-no-timeout', context).on('click.openWelcome15', function (event) {
        event.preventDefault();
        self.showSignupForm();
      });
      // If the user hasn't seen the popup, then show it to them
      if (self.showOfferOnPage()) {
        // this.showSignupForm();
        // Don't show the offer popup on the professional and location page and also when the customer lands on the page through extole
        if (
          !$('body').hasClass('section-professional') &&
          !$('body').hasClass('section-locations') &&
          !$('body').hasClass('get_facts') &&
          !extoleString.test(siteSearch)
        ) {
          this.showSignupForm(self.defaultTimeout);
        }
      }

      // Don't show the offer popup on the professional and location page and also when the customer lands on the page through extole
      if (
        !$('body').hasClass('section-professional') &&
        !$('body').hasClass('section-locations') &&
        !$('body').hasClass('get_facts') &&
        !extoleString.test(siteSearch)
      ) {
        $.cookie(cookieName, '1', {
          expires: 1,
          path: '/'
        });
      }

      // Bind to the email_signup.success event
      // When a user does the email sign up in the footer, we'll show them the welcome15 overlay
      // if the offer is still valid, and they haven't signed up already
      $(context).on('email_signup.success', function (event, rpcResponse) {
        var response = rpcResponse.getData(),
          userinfo = response.userinfo || {},
          templates = self.templates,
          content = '';
        var previously_opted_in_email_promotion = userinfo.previously_opted_in_email_promotion || 0;

        // commenting this out for now - might want to show already_signed_up again in the future
        // .. only show the overlay if the user hasn't already registered
        // if (previously_opted_in_email_promotion == 0) {
        content = templates.thanks;
        // } else {
        //  content = templates.already_signed_up;
        // }

        if (content.length > 0) {
          // @TODO: triggering an overlay will close the special-offers-tray. We don't want it to do that.
          var html = self.getTemplateContent(content);

          self.openWindow(html, {
            onComplete: function () {
              $.colorbox.resize();
            }
          });
        }
      });

      // Bind to the email_signup.error event for no particular reason except that it exists
      // Do we need to show an error message?
      // $(context).on('email_signup.error', function(event, rpcResponse) {});

      // Bind to the cbox_complete event. This fires anytime a colorbox window is opened.
      // This event handler adds the disclaimer text to the overlays, but could be used for similiar tasks as well.
      // $(context).on('cbox_complete', function() {
      // var overlay  = ($('body').hasClass('device-mobile') ? self.overlay.mobile : self.overlay.pc);
      // $element = $('#cboxContent', '#colorbox.' + overlay.className.split(" ")[0]);
      // });
      $('.js-welcome-15-no-timeout').on('click', function (e) {
        e.preventDefault();
        self.showSignupForm(0);
      });
    }
  };

  // --------
  // Callbacks and whatnot

  site.offers.welcome15 = {
    behavior: Drupal.behaviors.ELB_welcome15,

    getOverlayContent: function () {
      return $('#cboxLoadedContent');
    },

    emailSignupRedirect: function () {
      var dest = $('.js-site-email-signup .form-submit').attr('data-dest');

      dest = dest || '';
      if (/^\/.+$/.test(dest)) {
        dest = dest.replace(/^(\s|\/)+/, '');
      }
      if (/^(?!http|\/).+$/.test(dest)) {
        dest = Drupal.settings.basePath + dest;
      }
      var email = document.getElementsByName('search-first');

      if (email !== '' && email.length > 0) {
        var emailvalue = email[0].value;

        if (emailvalue != $('#default').val() && emailvalue !== '') {
          dest = dest + '?email=' + escape(emailvalue);
          $('.js-site-email-signup .js-offers-email-signup').addClass('hidden');
          window.location.href = dest;
        } else {
          $('.js-site-email-signup .js-offers-email-signup').removeClass('hidden');
        }
      }
    },

    /**
     * Initialize the signup form
     */
    initSignUpForm: function () {
      var self = site.offers.welcome15,
        overlay = self.getOverlayContent();

      // we set visibility to 0 onOpen, to avoid "sliding" while the css is loading
      // now we will:
      // 1) fade them both out
      $('.welcome15-overlay #cboxContent,#cboxOverlay').fadeOut(1, function () {
        // 2) set visibility on and fade in the overelay
        $('#cboxOverlay')
          .css('visibility', 'visible')
          .fadeIn(500, function () {
            // 3) set visibility and fade in the content (very quickly)
            $('.welcome15-overlay #cboxContent').css('visibility', 'visible').fadeIn(100);
          });
      });

      overlay.find('form').submit(function (e) {
        e.preventDefault();
        if (Drupal.settings.elc_common && Drupal.settings.elc_common.elc_site_type.ecommerce) {
          var form = this;
          var fields = [
            '_SUBMIT',
            'LAST_SOURCE',
            'COUNTRY_ID',
            'LANGUAGE_ID',
            'PC_EMAIL_PROMOTIONS',
            'redirect_or_text',
            'redirect',
            'PC_EMAIL_ADDRESS'
          ];
          var paramObj = {};

          $.each(fields, function (i, name) {
            var field = $('input[name="' + name + '"]', form),
              undef;

            if (field != undef && field.length > 0) {
              paramObj[name] = field.val();
            }
          });

          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [paramObj],
            onSuccess: function (jsonRpcResponse) {
              var response = jsonRpcResponse.getData(),
                userinfo = response.userinfo || {};
              var previously_opted_in_email_promotion = userinfo.previously_opted_in_email_promotion || 0;
              var b = self.behavior,
                t = b.templates,
                c = t.error, // Default template to return
                e = true, // Defaults to the error condition
                d = { message: "<p>There's been an error.</p>" }; // Error message

              // Figure out if the user has already signed up. If so, show 'em the already_signed_up template
              // c = (previously_opted_in_email_promotion == 0) ? t.thanks : t.already_signed_up;
              c = t.thanks;

              // And make sure there's no error to show
              e = false;

              // Remove any existing content, and show the error/thanks/already_signed_up template
              overlay.empty().append(e ? b.getTemplateContent(c, d) : b.getTemplateContent(c));
              $.colorbox.resize();

              // Act like we're opening a new colorbox
              // $(document).trigger('cbox_complete');
            },
            onFailure: function (jsonRpcResponse) {
              // Store messages and display them in error div element.
              var messages = jsonRpcResponse.getMessages();

              $('.welcome-15').toggleClass('error-msg');
              $('.js-popup-offer-error-messages').empty().html(messages[0].text).removeClass('hidden');
            }
          });
        } else {
          self.emailSignupRedirect();
        }

        return false;
      });
    }
  };
})(jQuery);
